.iq__menu__content{
    width: 100%;
    position: fixed;
    z-index: 10;
    background-color: white;
    margin: 0 -16px;
}

.iq__whatsapp{
    width: 64px;
    height: 64px;
    background: linear-gradient(180deg, #49C14B 0%, #05853A 100%);
    position: fixed;
    bottom: 80px;
    right: 0px;
    background-image: url('/src/images/whatsapp.svg');
    background-repeat: no-repeat;
}

.iq__whatsapp a{
    display: block;
    width: 100%;
    height: 100%;
}

.iq__menu{
    display: flex;
    position: relative;
    align-items: center;
    border-bottom: 1px solid rgba(176,190,197,0.5);
    justify-content: center;
}

.iq__menu__logo{
    width: 80px;
    height: 22px;
    margin: 0 auto;
    position: absolute;
    margin-left: auto !important;
}

.iq__menu__logo svg{
    width: 100%;
    height: 100%;
}

.iq__menu__button{
    margin-left: auto !important;
}

.iq__menu__icon{
    width: 24px;
    height: 24px;
}

.iq__menu__sections__mobile{
    width: calc(100% - 48px);
    margin: 0 -16px;
    z-index: 5;
    display: flex;
    padding: 24px 32px 0 32px;
    position: absolute;
    flex-direction: column;
    background-color: white;
    box-shadow: 2px 2px 10px 0 rgb(38 50 56 / 10%);
}

.iq__menu__sections__mobile a{
    color: #263238;
    font-size: 14px;
    margin-bottom: 24px;
    text-decoration: none;
}

.iq__menu__sections__mobile a.active{
    color: #8BC34A;
}

.iq__menu__sections{
    display: none;
}

@media (min-width: 960px) {
    .iq__menu{
        padding: 22px 40px;
    }
    .iq__menu__content{
        width: 100%;
        position: fixed;
        z-index: 10;
        background-color: white;
        margin: 0 -40px;
        box-sizing: border-box;
    }
    .iq__menu__logo{
        width: 120px;
        height: 33px;
        position: relative;
        margin-left: 0;
    }
    .iq__menu__button{
        display: none !important;
    }
    .iq__menu__sections__mobile{
        display: none !important
    }
    .iq__menu__sections{
        display: flex;
        justify-content: space-between;
    }
    .iq__menu__sections a{
        color: #263238;
        margin: 0 10px;
        text-decoration: none;
        padding-bottom: 6px;
    }
    .iq__menu__sections a.active{
        font-family: 'Ubuntu-Bold', sans-serif;
        font-weight: 900;
        background-size: 4px 4px;
        background-image: url('/src/images/dot.png');
        background-repeat: no-repeat;
        background-position: 50% 20px;
    }
}